// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'external' %> to the appropriate
// layout file, like app/views/layouts/external.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import './external.css'
import TileLayer from 'ol/layer/Tile'
import Map from 'ol/Map'
import View from 'ol/View'
import * as olProj from 'ol/proj'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Circle from 'ol/style/Circle'
import XYZSource from 'ol/source/XYZ'
import { boundingExtent } from 'ol/extent'

window.solviExternal = Object.assign(window.solviExternal || {}, {
  addSmoothScroll: function () {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      })
    })
  },
  findUs: function (mapboxToken) {
    const locations = [
      [11.966835, 57.708104],
      [-78.851039, 35.732172],
    ].map(location => olProj.fromLonLat(location))
    const extent = boundingExtent(locations)
    const view = new View()

    const map = new Map({
      target: 'map',
      controls: [],
      layers: [
        new TileLayer({
          source: new XYZSource({
            maxZoom: 17,
            tileSize: [512, 512],
            url:
              'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=' +
              mapboxToken,
          }),
        }),
      ],
      view,
    })

    const contactBox = document.querySelector('#contact-box')

    view.fit(extent, { padding: [30, contactBox.clientWidth + 60, 30, 30] })

    const layer = new VectorLayer({
      source: new VectorSource({
        features: locations.map(
          location =>
            new Feature({
              geometry: new Point(location),
            })
        ),
      }),
      style: new Style({
        image: new Circle({
          radius: 10,
          fill: new Fill({ color: [255, 255, 255, 1] }),
          stroke: new Stroke({ color: [0, 166, 204], width: 9 }),
        }),
      }),
    })
    map.addLayer(layer)
  },
})
